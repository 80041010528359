

.custom-marker {
    list-style-type: square; /* تأكيد تغيير نوع العلامة */
    margin-left: 30px;
  }
  .custom-marker li {
    font-size: 0.9rem; 
    padding: 6px 2px;
  }

.custom-marker li::marker {
    color: #844212; 
    font-size: 1.1rem; 
    font-weight: bold; 
}