.slider-container {
    width: 50%;
    margin: 20px auto;
}

.slider-container img {
    width: 100%;
    height: auto;
    padding-left: 5px;
}


.slider-upark , .slider-muruj{
    width: 90%;
    margin: 10px auto;
    display: flex;
    flex-wrap: wrap;
}


@media (max-width:769px) {
    .slider-container , .slider-upark  , .slider-muruj  {
        width: 75%;
    }
}

@media (max-width:446px) {
    .slider-container , .slider-upark  , .slider-muruj  {
        width: 95%;
    }
}